<template>
    <div>
      <div class="content-header row">
        <div class="content-header-left col-md-6 col-12 mb-2">
          <div class="row breadcrumbs-top">
            <h2 class="content-header-title float-start mb-0">
              Villa Theater Master
            </h2>
          </div>
        </div>
        <div class="content-header-right col-md-6 col-12 mb-2">
          <div class="col-12">
            <div class="breadcrumb-wrapper">
              <ol class="breadcrumb float-end">
                <li class="breadcrumb-item">
                  <router-link to="/dashboard">Dashboard</router-link>
                </li>
                <li class="breadcrumb-item active">Villa Theater Master</li>
              </ol>
            </div>
          </div>
        </div>
      </div>
      <div class="content-body">
        <section id="dashboard-ecommerce">
          <div class="row match-height">
            <div class="col-xl-12 col-md-12 col-12">
              <div class="card card-congratulation-medal">
                <div class="card-body">
                  <div class="row mb-1">
                    <div class="d-flex col-12">
                      <div class="float-start col-6">
                        <div class="input-group input-group-merge w-50">
                          <span class="input-group-text" id="basic-addon-search2"
                            ><vue-feather type="search"></vue-feather></span>
                          <input
                            type="text"
                            class="form-control"
                            placeholder="Search..."
                            aria-label="Search..."
                            aria-describedby="basic-addon-search2"
                            v-model="search"
                            @keyup="searchData"
                          />
                        </div>
                      </div>
                      <!-- data-bs-toggle="modal"
                      data-bs-target="#modals-slide-in" -->
                      <div class="float-end col-6">
                        <button
                          type="button"
                          class="btn btn-gradient-primary float-end"
                          @click="add"
                        >
                          Add
                        </button>
                      </div>
                    </div>
                  </div>
                  <section id="ajax-datatable">
                    <div class="row">
                      <div class="col-12">
                        <div class="card">
                          <div class="card-datatable">
                            <table
                              class="dt-column-search table table-responsive"
                            >
                              <thead>
                                <tr>
                                  <template v-for="head in header" :key="head">
                                    <th>{{ head }}</th>
                                  </template>
                                </tr>
                              </thead>
                              <tbody>
                                <tr v-show="list.length > 0" v-for="(li, index) in list" :key="li.id">
                                  <td>{{ index + paginationData.from }}</td>
                                  <td>{{ li.movie_name }}</td>
                                  <td><img :src="li.image" class="img-fluid" alt="img-placeholder"></td>
                                  <td>{{ li.show_time }}</td>
                                  <td>{{ li.total_seats }}</td>
                                  <td>{{ li.date }}</td>
                                  <td>{{ li.per_seat_price }}</td>
                                  <td>{{ li.screen_no }}</td>
                                  <td>{{ li.created_at }}</td>
                                  <td>{{ li.updated_at }}</td>
                                  <td class="d-flex">
                                    <div
                                      class="d-flex justify-content-start align-items-center mb-75"
                                    >
                                      <span class="me-75">
                                        <div
                                          class="avatar bg-light-primary rounded"
                                          @click="edit(li.id)"
                                        >
                                          <div class="avatar-content">
                                            <vue-feather type="edit" size="15"></vue-feather>
                                          </div>
                                        </div>
                                      </span>
                                    </div>
                                    <div
                                      class="d-flex justify-content-start align-items-center mb-75"
                                    >
                                      <span class="me-75">
                                        <div
                                          class="avatar bg-light-danger rounded"
                                          @click="deleteDes(li.id)"
                                        >
                                          <div class="avatar-content">
                                            <vue-feather type="trash-2" size="15"></vue-feather>
                                          </div>
                                        </div>
                                      </span>
                                    </div>
                                  </td>
                                </tr>
                                <tr v-show="list.length === 0">
                                  <td colspan="11" class="text-center">No Data Found</td>
                                </tr>
                              </tbody>
                              <tfoot>
                                <tr>
                                  <template v-for="head in header" :key="head">
                                    <th>{{ head }}</th>
                                  </template>
                                </tr>
                              </tfoot>
                            </table>
                            <div class="row d-flex">
                              <div class="col-12 d-flex">
                                <div class="col-6 showing_entry">
                                  Showing {{ paginationData.from }} to
                                  {{ paginationData.to }} of
                                  {{ paginationData.total }} entries
                                </div>
                                <div class="col-6">
                                  <Pagination
                                    :data="paginationData"
                                    @pagination-change-page="getResults"
                                    show-disabled="true"
                                    limit="3"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
    <teleport to="#modal">
      <div class="modal modal-slide-in new-user-modal fade" id="modals-slide-in" :class="isShow" :style="{display: isDisplay}">
        <div class="modal-dialog">
          <form
            class="add-new-user modal-content pt-0"
            method="post"
            @submit.prevent="submitForm"
          >
            <input type="hidden" name="id" :v-modal="form.id" />
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              @click="[isShow='', isDisplay='none']"
            >
              ×
            </button>
            <div class="modal-header mb-1">
              <h4 class="modal-title" id="exampleModalLabel">
                {{ modalName }} Villa Theater Movie
              </h4>
            </div>
            <div class="modal-body flex-grow-1">
              <div class="mb-1">
                <label class="form-label" for="basic-addon-name"
                  >Movie name</label
                >
                <input
                    type="text" class="form-control" name="movie_name" v-model="form.movie_name" placeholder="Movie Name" @keyup="
                    form.movie_name.length > 0
                        ? [(movieNameError = 'none'), (this.disabled = false)]
                        : (movieNameError = 'block')
                        "/>
                <div class="invalid-feedback fw-bold fst-italic" :style="{display: movieNameError}">
                  Please Fill Movie name.
                </div>
              </div>
              <div class="row mb-1">
                <div class="col-10">
                    <label class="form-label" for="basic-addon-name">Image</label>
                    <input type="file" ref="file" class="form-control" name="image" placeholder="Image" @change="uploadImage" />
                </div>
                <div class="col-2">
                    <div id="user-avatar-section">
                        <div id="d-flex justify-content-start">
                            <img v-if="url" :src="url" class="img-fluid rounded" height="104" width="104"/>
                        </div>
                    </div>
                </div>
              </div>
              <div class="mb-1">
                <label class="form-label" for="basic-addon-name"
                  >Show Time</label
                >
                  <div class='tag-input'>
                    <div v-for='(tag, index) in form.tags' :key='tag' class='tag-input__tag'>
                      {{ tag }}
                      <span @click='removeTag(index)'>x</span>
                    </div>
                    <input
                      type='text'
                      placeholder="Facility Name"
                      class='tag-input__text form-control'
                      @keydown.enter='addTag'
                      @keydown.prevent.,='addTag'
                      @keydown.delete='removeLastTag'
                      @keyup="
                        form.tags !== undefined  ? [(showTimeError = 'none'), (disabled = false)]
                          : (showTimeError = 'block')
                      "
                    />
                  </div>
                <div
                  class="invalid-feedback fw-bold fst-italic"
                  :style="{display: showTimeError}"
                >
                  Please enter Time slot.
                </div>
              </div>
              <div class="mb-1">
                <label class="form-label" for="basic-addon-name"
                  >Total Seats</label
                >
                <input
                    type="number" class="form-control" name="total_seats" v-model="form.total_seats" placeholder="Total Seats" @keyup="
                    form.total_seats.toString().length > 0
                        ? [(totalSeatsError = 'none'), (this.disabled = false)]
                        : (totalSeatsError = 'block')
                        "/>
                <div class="invalid-feedback fw-bold fst-italic" :style="{display: totalSeatsError}">
                  Please Fill Total Seats.
                </div>
              </div>
              <div class="mb-1">
                <label class="form-label" for="basic-addon-name">Date</label>
                <Datepicker 
                    type="date"
                    name="date"
                    @closed="form.date.length === 0
                        ? [(dateError = 'none'), (disabled = false)]
                        : (dateError = 'block')"
                    :min="new Date().toISOString().substr(0, 10)"
                    v-model="form.date"
                    calendar-button="false" 
                    format="dd-MM-yyyy"
                    value-format="dd-MM-yyyy"
                    :min-date='new Date()'
                    modelAuto multiCalendars multiCalendarsSolo />
                <!-- <div class="invalid-feedback fw-bold fst-italic" :style="{display: dateError}"> Please select date of Movie. </div> -->
              </div>
              <div class="mb-1">
                <label class="form-label" for="basic-addon-name"
                  >Per Seat Price</label
                >
                <input
                    type="number" class="form-control" name="per_seat_price" v-model="form.per_seat_price" placeholder="Per Seat Price" @keyup="
                    form.per_seat_price.toString().length > 0
                        ? [(perSeatPriceError = 'none'), (this.disabled = false)]
                        : (perSeatPriceError = 'block')
                        "/>
                <div class="invalid-feedback fw-bold fst-italic" :style="{display: perSeatPriceError}">
                  Please Fill Per Seat Price.
                </div>
              </div>
              <div class="mb-1">
                <label class="form-label" for="basic-addon-name"
                  >Screen No</label
                >
                <Select2 v-model="form.screen_no" :options="screen" @change="myChangeEvent($event)" :settings="{ placeholder: 'Choose Screen no' }" @select="mySelectEvent($event)"/>
                <div
                    class="invalid-feedback fw-bold fst-italic"
                    :style="{display: screenNoError}"
                >
                    Please Choose Screen number.
                </div>
              </div>
              <button
                type="submit"
                class="btn btn-primary me-1 data-submit"
                :disabled="disabled"
              >
                Submit
              </button>
              <button
                type="reset"
                class="btn btn-outline-secondary"
                :disabled="disabled"
                data-bs-dismiss="modal"
                @click="[isShow='', isDisplay='none']"
              >
                Cancel
              </button>
            </div>
          </form>
        </div>
      </div>
    </teleport>
  </template>
  
  <script>
  import axios from "axios";
  import LaravelVuePagination from "laravel-vue-pagination";
  import Select2 from 'vue3-select2-component';
  import Datepicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'
import moment from 'moment';
import Form from 'vform';
  
  export default {
    name: "Theater",
    components: {
      Pagination: LaravelVuePagination,
      Select2,
      Datepicker
    },
    data() {
      return {
        form: new Form({
            id: null,
            movie_name: "",
            image: "",
            tags: [],
            total_seats: "",
            date: "",
            per_seat_price: "",
            screen_no: "",
        }),
        url: null,
        screen: [
            {'id': 1, 'text': 1},
            {'id': 2, 'text': 2},
        ],
        response: "",
        disabled: false,
        header: [],
        list: [],
        modalName: "Add",
        paginationData: {},
        search: null,
        movieNameError: "none",
        totalSeatsError: "none",
        showTimeError: "none",
        perSeatPriceError: "none",
        screenNoError: "none",
        dateError: "none",
        isShow: "",
        isDisplay: "none",
        token: localStorage.getItem('auth_token'),
      };
    },
    async mounted() {
      this.header = [
        "Sr. no.",
        "Movie name",
        "Image",
        "Show Time",
        "Total Seats",
        "Date",
        "Per Seat Price",
        "Screen No",
        "Created Date",
        "Updated Date",
        "Action",
      ];
      this.getResults();
    },
    methods: {
      addTag(event) {
        event.preventDefault();
        let val = event.target.value.trim()
        if (val.length > 0) {
          if (this.form.tags.length >= 1) {
            for (let i = 0; i < this.form.tags.length; i++) {
              if (this.form.tags[i] === val) {
                this.$toast.warning(val + " Tag Already Exist");
                return false
              }
            }
          }
          this.form.tags.push(val)
          event.target.value = ''
          // console.log(this.form.tags)
        }
      },
      removeTag(index) {
        this.form.tags.splice(index, 1)
      },
      removeLastTag(event) {
        if (event.target.value.length === 0) {
          this.removeTag(this.form.tags.length - 1)
        }
      },
      mySelectEvent({ id }) {
        this.form.screen_no = id;
        this.disabled = false;
        if(this.form.screen_no.length > 0) {
          this.screenNoError = "none";
        }
      },
      async add() {
        (this.form.id = null),
        (this.form.screen_no = ""),
        (this.form.movie_name = ""),
        (this.form.image = ""),
        (this.form.date = ""),
        (this.form.per_seat_price = ""),
        (this.form.total_seats = ""),
        (this.form.screen_no = ""),
        (this.form.tags = []),
        (this.url = ""),
        (this.response = "");
        this.$refs.file.value = "";
        this.isShow = "show"
        this.isDisplay = "block"
      },
      async getResults(page = 1) {
        await axios
          .get(process.env.VUE_APP_API_URL+"/theater_master?page=" + page, 
          {
            headers: {
              "Content-Type": "application/json",
              'Accept': "application/json",
              "Authorization": `Bearer ${this.token}`
            }
          })
          .then((response) => {
            this.paginationData = response.data.data;
          });
        this.list = this.paginationData.data;
      },
      uploadImage(e) {
            let img = e.target.files[0]
            this.form.image = img;
            this.url = URL.createObjectURL(img);
        },  
      async submitForm() {
        this.disabled = true;
        if (this.form.movie_name.length === 0) {
          this.movieNameError = "block";
        } else if (this.form.per_seat_price.length === 0) {
          this.perSeatPriceError = "block";
        } else if (this.form.screen_no.length === 0) {
          this.screenNoError = "block";
        } else if (this.form.tags === undefined) {
          this.showTimeError = "block";
        } else {
          this.screenNoError = "none";
          this.showTimeError = "none";
            
          this.form.date = moment(this.form.date).format('YYYY-MM-DD')
          const array = JSON.parse(JSON.stringify(this.form.tags))
          const jsonString = JSON.stringify(Object.assign({}, array))
          this.form.show_time = JSON.parse(jsonString);

        var apiUrl = '';
        if (this.form.id) {
        apiUrl = process.env.VUE_APP_API_URL+'/theater_master/'+this.form.id+"/update/";
        } else {
        apiUrl = process.env.VUE_APP_API_URL+"/theater_master";
        }
            
          await this.form.post(apiUrl,
          {
            headers: {
              "Content-Type": "application/json",
              "Accept": "application/json",
              "Authorization": `Bearer ${this.token}`
            },
          })
          .then((response) => {
            if (response.data.status) {
              this.$toast.success(response.data.message);
              this.isShow = ""
              this.isDisplay = "none"
              this.disabled = false;
              this.getResults();
            } else {
              this.$toast.error(response.data.error?.[0]);
              this.disabled = false;
            }
          })
          .catch((error) => {
            this.$toast.error(error);
            this.disabled = false;
          });
        //   if (this.form.id) {
        //     await axios.put(process.env.VUE_APP_API_URL+'/'+this.form.id+"/theater_master/",
        //     {
        //         show_time: JSON.parse(jsonString),
        //         screen_no: this.screen_no,
        //         movie_name: this.movie_name,
        //         total_seats: this.total_seats,
        //         per_seat_price: this.per_seat_price,
        //         date: this.date,
        //         image: this.image,
        //     },
        //     {
        //       headers: {
        //         "Content-Type": "application/json",
        //         "Accept": "application/json",
        //         "Authorization": `Bearer ${this.token}`
        //       },
        //     })
        //     .then((response) => {
        //       if (response.data.status) {
        //         this.$toast.success(response.data.message);
        //         this.isShow = ""
        //         this.isDisplay = "none"
        //         this.disabled = false;
        //         this.getResults();
        //       } else {
        //         this.$toast.error(response.data.error?.[0]);
        //         this.disabled = false;
        //       }
        //     })
        //     .catch((error) => {
        //       this.$toast.error(error);
        //       this.disabled = false;
        //     });
        //   } else {
        //     await axios.post(process.env.VUE_APP_API_URL+"/theater_master",
        //       {
        //         show_time: JSON.parse(jsonString),
        //         screen_no: this.screen_no,
        //         movie_name: this.movie_name,
        //         total_seats: this.total_seats,
        //         per_seat_price: this.per_seat_price,
        //         date: this.date,
        //         image: this.image,
        //       },
        //       {
        //         headers: {
        //           "Content-Type": "application/json",
        //           "Accept": "application/json",
        //           "Authorization": `Bearer ${this.token}`
        //         },
        //       }
        //       )
        //       .then((response) => {
        //         if (response.data.status) {
        //           this.$toast.success(response.data.message);
        //           this.isShow = ""
        //           this.isDisplay = "none"
        //           this.disabled = false;
        //           this.getResults();
        //         } else {
        //           this.$toast.error(response.data.error?.[0]);
        //           this.disabled = false;
        //         }
        //       })
        //       .catch((error) => {
        //         this.$toast.error(error);
        //         this.disabled = false;
        //       });
        //   }
        }
      },
      deleteDes(id) {
        this.$swal({
          title: "Are you sure?",
          text: "You won't be able to revert this!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Yes, delete it!",
          customClass: {
            confirmButton: "btn btn-primary",
            cancelButton: "btn btn-outline-danger ms-1",
          },
          buttonsStyling: false,
        }).then(result => {
          if (result.value) {
            axios
              .delete(process.env.VUE_APP_API_URL+"/theater_master/" + id,
                {
                  headers: {
                    "Content-Type": "application/json",
                    "Accept": "application/json",
                    "Authorization": `Bearer ${this.token}`
                  },
                }
              )
              .then(response => {
                this.getResults();
                if (response.data.status) {
                  this.$toast.success(response.data.message);
                } else {
                  this.$toast.error(response.data.error?.[0]);
                }
              })
              .catch((error) => {
                this.$toast.error(error);
              });
          }
        });
      },
      async edit(id) {
        this.form.tags = [];
        this.movieNameError= "none"
        this.totalSeatsError= "none"
        this.showTimeError= "none"
        this.perSeatPriceError= "none"
        this.screenNoError= "none"
        this.disabled= false
        const output = await axios.get(process.env.VUE_APP_API_URL+"/theater_master" +(id ? "/" + id : ""),
                {
                  headers: {
                    "Content-Type": "application/json",
                    "Accept": "application/json",
                    "Authorization": `Bearer ${this.token}`
                  },
                });
              console.log(output);
        this.form.id = output.data.data?.id;
        this.form.movie_name = output.data.data?.movie_name;
        this.form.date = output.data.data?.date;
        this.form.image = output.data.data?.image;
        this.form.per_seat_price = output.data.data?.per_seat_price;
        this.form.total_seats = output.data.data?.total_seats;
        this.form.screen_no = output.data.data?.screen_no;
        this.url = output.data.data?.image_url;
        const tagName = output.data.data?.show_time;
        tagName.map((val) => {
          this.form.tags.push(val)
        }) 
        this.modalName = "Edit";
        this.isShow = "show"
        this.isDisplay = "block"
      },
      async searchData() {
        await axios
          .post(process.env.VUE_APP_API_URL+"/theater_master/search",
            {
              search: this.search,
            },
            {
              headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": `Bearer ${this.token}`
              },
            }
          )
          .then((response) => {
            if (response.data.status) {
              this.list = response.data.data.data;
              this.isShow = ""
              this.isDisplay = "none"
            } else {
              this.$toast.error(response.data.error?.[0]);
            }
          })
          .catch((error) => {
            this.$toast.error(error);
          });
      },
    },
  };
  </script>
  <style scoped>
  /* a {
    position: absolute;
    right: 15px;
    bottom: 15px;
    font-weight: bold;
    text-decoration: none;
    color: #00003a;
    font-size: 20px;
  } */
    
    
  /*tag input style*/
    
    .tag-input {
      width: 100%;
      border: 1px solid #D9DFE7;
      background: #fff;
      border-radius: 4px;
      font-size: 0.9em;
      min-height: 45px;
      box-sizing: border-box;
      padding: 0 10px;
      margin-bottom: 10px;
    }
  
    .tag-input__tag {
      height: 24px;
      color: white;
      float: left;
      font-size: 14px;
      margin-right: 10px;
      background-color: #7367f0;
      border-radius: 15px;
      margin-top: 10px;
      line-height: 24px;
      padding: 0 8px;
      transition: 0.1s ease background;
    }
  
    .tag-input__tag > span {
      cursor: pointer;
      opacity: 0.75;
      display: inline-block;
      margin-left: 8px;
    }
  
    .tag-input__text {
      border: none;
      outline: none;
      font-size: 1em;
      line-height: 40px;
      background: none;
    }
  </style>